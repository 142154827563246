import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild,
    ViewEncapsulation } from '@angular/core';
import Dropzone from "dropzone";
import { Subscription } from "rxjs";

import { KnowledgeService } from "../../../core/services/knowledge.service";

// Using ViewEncapsulation.None to avoid Angular's style encapsulation
// This allows defining styles for Dropzone elements within this component, ensuring that Dropzone styles are not encapsulated and can be customized as needed.
@Component({
    selector: 'app-attachment-upload',
    templateUrl: './attachment-upload.component.html',
    styleUrl: './attachment-upload.component.scss',
    encapsulation: ViewEncapsulation.None
})
export class AttachmentUploadComponent implements AfterViewInit, OnDestroy {
    @ViewChild('attachmentupload') attachmentUpload!: ElementRef;
    @Input() initialFiles: File[] = [];
    @Output() uploadedDropzone: EventEmitter<File[]> = new EventEmitter<File[]>();

    attachmentDropImage: string = 'assets/icons/drop.png';
    attachmentLabel: string = 'Attachment upload (Optional)'
    dropzone: any;

    knowledgeAttachments: File[] = [];

    private attachmentsSubscription!: Subscription;

    constructor(private knowledge: KnowledgeService) { }

    ngAfterViewInit(): void {
        this.initializeDropzone();

        this.attachmentsSubscription = this.knowledge.getSelectedKnowledgeAttachments().subscribe(attachments => {
            this.knowledgeAttachments = attachments ?? [];
            this.loadKnowledgeAttachments();
        });
    }

    private loadKnowledgeAttachments() {
        if (this.dropzone && this.knowledgeAttachments.length > 0) {
            this.knowledgeAttachments.forEach(file => {
                this.dropzone.emit("addedfile", file);
                this.dropzone.emit("complete", file);
                this.dropzone.files.push(file);
            });
        }
    }

    // TODO think about how to handle multiple dropzones
    // Dropzone service is probably a better place to handle this
    initializeDropzone() {
        Dropzone.autoDiscover = false;

        //TODO now is not supported csv, pptx, xlsx, mp4
        //acceptedFiles: '.txt,.pdf,.csv,.png,.jpg,.jpeg,.docx,.pptx,.xlsx,.mp4',
        if (this.attachmentUpload) {
            this.dropzone = new Dropzone("#attachmentupload", {
                url: 'upload',
                paramName: "attachment",
                acceptedFiles: '.txt,.pdf,.png,.jpg,.jpeg,.docx',
                maxFilesize: 50, // MB
                addRemoveLinks: true,
                autoProcessQueue: false, // TODO Auto upload files
                maxFiles: 3
            });

            this.dropzone.on("addedfile", (file: File) => {
                this.uploadedDropzone.emit(this.dropzone);
                const fileExtension = file.name.split('.').pop()?.toLowerCase();
                console.log('File extension:', fileExtension);

                let thumbnailUrl = '';
                if (fileExtension === 'docx') {
                    thumbnailUrl = 'assets/images/docx-thumbnail.png';
                } else if (fileExtension === 'pptx') {
                    thumbnailUrl = 'assets/images/pptx-thumbnail.png';
                } else if (fileExtension === 'xlsx') {
                    thumbnailUrl = 'assets/images/xlsx-thumbnail.png';
                } else if (fileExtension === 'pdf') {
                    thumbnailUrl = 'assets/images/pdf-thumbnail.png';
                } else if (fileExtension === 'csv') {
                    thumbnailUrl = 'assets/images/csv-thumbnail.png';
                } else if (fileExtension === 'txt') {
                    thumbnailUrl = 'assets/images/txt-thumbnail.png';
                }

                if (thumbnailUrl) {
                    this.dropzone.emit('thumbnail', file, thumbnailUrl);
                }
            });
            this.dropzone.on("removedfile", (file: File) => {
                this.uploadedDropzone.emit(this.dropzone);
            });
        }
    }

    isFileDropped(): boolean {
        return !!(this.dropzone && this.dropzone.files && this.dropzone.files.length > 0);
    }

    resetDropzone() {
        this.knowledgeAttachments = [];
        this.uploadedDropzone.emit([]);
        this.dropzone = null;
        this.knowledge.setSelectedKnowledgeAttachments([]);
    }

    ngOnDestroy(): void {
        this.resetDropzone();
        this.attachmentsSubscription.unsubscribe();
    }
}
