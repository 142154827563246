<div class="assistant-container">
    <div *ngIf="isAssistantsEmpty()" class="initial-container">
        <div class="button-container">
            <button id="id-add-assistant-button" type="button" class="add-button" (click)="createNewAssistant()">
                <app-plus></app-plus>
            </button>
        </div>
        <div class="description-container">
            <p>{{ descriptionSection[0] }}</p>
            <p>{{ descriptionSection[1] }}</p>
        </div>
    </div>
    <div *ngIf="!isAssistantsEmpty()" class="assistants-container">
        <div class="assistant-list-container">
            <div id="id-toggle-assistants" class="assistants-header-container">
                <div class="tooltip-container">
                    <button id="id-arrow-button" class="arrow-button" (click)="toggleDropdown()">
                        <app-arrow></app-arrow>
                    </button>
                    <p class="button-tooltip">Select Assistant</p>
                </div>
                <p (click)="toggleDropdown()" class="select-assistant"> {{ getSelectedAssistantLabel() }}</p>
                <div *ngIf="isSelectAssistant()" class="tooltip-container">
                    <button id="id-designs-assistant" class="designs-button" (click)="goToAssistant()">
                        <app-go-to-assistant></app-go-to-assistant>
                    </button>
                    <p class="button-tooltip">Go to Assistant</p>
                </div>
                <div *ngIf="isSelectAssistant()" class="menu-container">
                    <button id="id-menu-assistant" class="menu-button" (click)="toggleAssistantMenu()"
                            appOutsideClick (outsideClick)="closeAssistantMenu()">
                        <app-menu></app-menu>
                    </button>
                    <div *ngIf="dropdownAssistantMenu" class="assistant-menu">
                        <button id="id-delete-assistant" class="delete-button" (click)="deleteSelectedAssistant()">
                            <app-delete></app-delete>
                            <p>Delete</p>
                        </button>
                        <button id="id-edit-assistant" class="edit-assistant" (click)="editSelectedAssistant()">
                            <app-edit></app-edit>
                            <p>Edit</p>
                        </button>
                    </div>
                </div>
                <div class="create-assistant-container">
                    <button id="id-create-assistant" type="button" class="create-assistant-button" (click)="createNewAssistant()">
                        <app-plus></app-plus>
                        <span>{{ createAssistantButton }}</span>
                    </button>
                </div>
            </div>
        </div>

        <div class="tabs-container">
            <div id="id-tab-knowledge" class="tab" [class.active]="activeTab === 'knowledge'" (click)="selectTab('knowledge')">
                {{ assistantKnowledgeLabel }}
            </div>
            <div id="id-tab-action" class="tab" [class.active]="activeTab === 'action'" (click)="selectTab('action')">
                {{ assistantActionLabel }}
            </div>
            <div id="id-tab-analytics" class="tab" [class.active]="activeTab === 'analytics'" (click)="selectTab('analytics')">
                {{ assistantAnalyticsLabel }}
            </div>
            <div id="id-tab-configuration" class="tab"
                 [ngClass]="{
                        'activeConfig': activeTab === 'configuration' && isSelectAssistant(),
                        'active': activeTab === 'configuration' && !isSelectAssistant()
                    }" (click)="selectTab('configuration')">
                {{ assistantConfigurationLabel }}
            </div>
        </div>
        <div *ngIf="activeTab === 'knowledge'" class="scroll-container">
            <div *ngIf="!isSelectAssistant()" class="content-center">
                <img [src]="cursorImage" alt="Select Icon">
                <p>Start with Assistant selection, then manage its knowledge.</p>
            </div>
            <div *ngIf="isSelectAssistant() && isAssistantKnowledgeListEmpty()" class="content-center">
                <div class="center-container">
                    <button id="id-add-knowledge-button" type="button" class="add-button" (click)="assignKnowledge()">
                        <app-plus></app-plus>
                    </button>
                    <p>{{ knowledgeSection }}</p>
                </div>
            </div>
            <div *ngIf="isSelectAssistant() && !isAssistantKnowledgeListEmpty()" class="knowledge-container">
                <div class="content-full">
                    <div class="assign-element-table">
                        <div *ngFor="let knowledge of selectedAssistant?.knowledge; let i = index" class="table-row">
                            <div class="table-column name">{{ knowledge.name }}</div>
                            <!--<div class="table-column options">
                                <button id="id-remove-knowledge" class="delete-button" (click)="removeKnowledgeFromAssistant(i)">
                                    <app-delete></app-delete>
                                </button>
                            </div>-->
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="isSelectAssistant() && !isAssistantKnowledgeListEmpty()" class="manage-button-container">
                <button id="id-assign-new-knowledge" type="button" class="assign-button" (click)="assignKnowledge()">
                    <app-plus></app-plus>
                    <span>{{ addKnowledgeToAssistantButton }}</span>
                </button>
            </div>
        </div>

        <div *ngIf="activeTab === 'action'" class="scroll-container">
            <div *ngIf="!isSelectAssistant()" class="content-center">
                <img [src]="cursorImage" alt="Select Icon">
                <p>Start with Assistant selection, then manage its actions.</p>
            </div>
            <div *ngIf="isSelectAssistant() && isAssistantActionListEmpty()" class="content-center">
                <div class="center-container">
                    <button id="id-add-action-button" type="button" class="add-button" (click)="assignAction()">
                        <app-plus></app-plus>
                    </button>
                    <p>{{ actionSection }}</p>
                </div>
            </div>
            <div *ngIf="isSelectAssistant() && !isAssistantActionListEmpty()" class="action-container">
                <div class="content-full">
                    <div class="assign-element-table">
                        <div *ngFor="let action of selectedAssistant?.actions; let i = index" class="table-row">
                            <div class="table-column name">{{ action.name }}</div>
                            <!--<div class="table-column options">
                                <button id="id-remove-action" class="delete-button" (click)="removeActionFromAssistant(i)">
                                    <app-delete></app-delete>
                                </button>
                            </div>-->
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="isSelectAssistant() && !isAssistantActionListEmpty()" class="manage-button-container">
                <button id="id-assign-new-action" type="button" class="assign-button" (click)="assignAction()">
                    <app-plus></app-plus>
                    <span>{{ addActionToAssistantButton }}</span>
                </button>
            </div>
        </div>

        <div *ngIf="activeTab === 'analytics'" class="analytics-container">
            <div *ngIf="!isSelectAssistant()" class="content-center">
                <img [src]="cursorImage" alt="Select Icon">
                <p>Start with Assistant selection, then manage its analytics.</p>
            </div>
        </div>

        <div *ngIf="activeTab === 'configuration'" class="configuration-container">
            <div *ngIf="!isSelectAssistant()" class="content-center">
                <img [src]="cursorImage" alt="Select Icon">
                <p>Start with Assistant selection, then manage its configuration.</p>
            </div>
            <app-assistant-configuration *ngIf="isSelectAssistant()"></app-assistant-configuration>
        </div>
    </div>
</div>
