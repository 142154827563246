<div class="dialog-header">
    <h2>{{ dialogTitle }}</h2>
    <button id="id-edit-close-knowledge-dialog" type="button" class="close-button" (click)="closeDialog()">
        <app-close></app-close>
    </button>
</div>
<!--<img *ngIf="fileUrl" [src]="fileUrl" alt="Preuzeta slika" />
<a [href]="fileUrl" download="attachment.png">Preuzmi datoteku</a>-->
<mat-dialog-content>
    <div class="dialog-container">
        <div class="selection-container" [ngClass]="getClass()">
            <div class="name-column">
                <label for="id-knowledge-name">{{ nameLabel }}</label>
                <input type="text" id="id-knowledge-name" name="knowledge-name" [(ngModel)]="knowledgeName" [placeholder]="knowledgeNameLabel">
            </div>

            <div class="component-container" [ngClass]="getClass()">
                <! -- UPDATE TEXT/FILE SOURCE -->
                <div *ngIf="knowledgeType === 'Text' || knowledgeType === 'File'" class="content-container">
                    <div class="textarea-container">
                        <label for="id-knowledge-text">{{ textLabel }}</label>
                        <textarea id="id-knowledge-text" [(ngModel)]="knowledgeText" [placeholder]="textareaLabel"></textarea>
                    </div>
                    <app-attachment-upload (uploadedDropzone)="handleAttachmentDropzone($event)"></app-attachment-upload>
                </div>
                <! -- UPDATE TRIPLET SOURCE -->
                <app-triplet-knowledge *ngIf="knowledgeType === 'Triplet'" [tripletsFromEditingKnowledge]="triplets" (uploadedTriplets)="handleTriplets($event)"></app-triplet-knowledge>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end" class="dialog-footer">
    <button id="id-update-knowledge" type="button" class="add-button" (click)="updateKnowledge()" [disabled]="!isFormValid">{{ buttonLabel }}</button>
</mat-dialog-actions>
